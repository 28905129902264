import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import Img from '../components/image';
import Layout from '../components/layout';
import ContentContainerGrid from '../components/content-container-grid';
import Testimonial from '../components/testimonial';
import Metadata from '../components/metadata';
import Link from '../components/link';

export const HomeTemplate = ({
  image, testimonials, headline, subheader, featuredLinks,
}) => (
  <React.Fragment>
    <Styled.h1 sx={{ fontSize: [4, 6], textAlign: 'center' }}>{headline}</Styled.h1>
    <Styled.h2 sx={{ fontSize: [2, 3], textAlign: 'center' }}>{subheader}</Styled.h2>
    {image && (
      <Img
        src={image}
        alt={headline}
        imgStyle={{
          objectPosition: '50% 33%',
        }}
        sx={{
          height: '455px',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1,
            backgroundImage: (theme) => `linear-gradient(to bottom, ${theme.colors.background}, rgba(255,255,255,0.4) 40%, rgba(255,255,255,0) 51%)`,
          },
        }}
      />
    )}
    <ContentContainerGrid sx={{ mt: 4 }}>
      <div
        sx={{
          gridColumn: ['content-start / content-end', '2 / -2'],
          gridRow: 'footer',
          display: 'grid',
          gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
          gridGap: 4,
          mt: 3,
          mb: 4,
        }}
      >
        {featuredLinks.map(({ title, url, description }) => (
          <Link
            key={url}
            to={url}
            sx={{
              textDecoration: 'none',
              border: 'solid 1px #aaa',
              borderRadius: '10px',
              display: 'inline-block',
              color: 'primary',
              p: 4,
              ':hover': {
                bg: 'bgmuted',
                borderColor: 'bgmuted',
              },
            }}
          >
            <Styled.h2>{title}</Styled.h2>
            <Styled.p>{description}</Styled.p>
          </Link>
        ))}
      </div>
    </ContentContainerGrid>
    <ContentContainerGrid>
      {testimonials.map((testimonial) => (
        <Testimonial key={testimonial.name} large {...testimonial} />
      ))}
    </ContentContainerGrid>
  </React.Fragment>
);

HomeTemplate.propTypes = {
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object.isRequired,
    }).isRequired,
  }),
  testimonials: PropTypes.array.isRequired,
  featuredLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  headline: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
};

HomeTemplate.defaultProps = {
  image: null,
};

const HomePage = ({ data }) => {
  const { mdx: page } = data;
  const { frontmatter: metadata } = page;

  return (
    <Layout>
      <Metadata pageData={metadata} />
      <HomeTemplate
        headline={metadata.headline}
        subheader={metadata.subheader}
        testimonials={metadata.testimonials}
        image={metadata.image}
        featuredLinks={metadata.featuredLinks}
      />
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object,
  }).isRequired,
};

export default HomePage;

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        headline
        subheader
        meta_description
        image {
          childImageSharp {
            fluid(maxWidth: 2600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        testimonials {
          name
          title
          organization
          quote
        }
        featuredLinks {
          description
          title
          url
        }
      }
    }
  }
`;
