/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const Cite = (props) => (
  <cite
    {...props}
    sx={{
      fontStyle: 'normal',
      display: 'block',
      ml: 4,
    }}
  />
);

const Testimonial = ({
  quote, name, title, organization, large,
}) => (
  <figure
    sx={{
      gridColumn: 'content-start / content-end',
      p: 0,
      mx: 0,
      my: 4,
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      '&:first-of-type': {
        borderTop: 'solid 1px #999',
        mt: 5,
        pt: 5,
      },
      '&:last-of-type': {
        borderBottom: 'solid 1px #999',
        mb: 5,
        pb: 5,
      },
      '&:first-of-type:last-of-type': {
        border: 0,
        p: 0,
      },
    }}
  >
    <FaQuoteLeft
      size="2em"
      sx={{
        position: 'absolute',
        left: 0,
        ml: [null, '-3em'],
      }}
    />
    <FaQuoteRight
      size="2em"
      sx={{
        position: 'absolute',
        right: 0,
        mr: [null, '-3em'],
      }}
    />
    <blockquote
      sx={{
        my: 0,
        mx: [4, 0],
        fontStyle: 'italic',
        fontSize: large ? 4 : null,
      }}
    >
      {quote}
    </blockquote>
    <footer
      sx={{
        mt: 3,
        mx: [4, 0],
        alignSelf: 'flex-end',
        fontSize: 0,
        '&:before': {
          content: '"—"',
          position: 'absolute',
        },
      }}
    >
      <Cite>{name}</Cite>
      <Cite>{title}</Cite>
      <Cite>{organization}</Cite>
    </footer>
  </figure>
);

Testimonial.propTypes = {
  quote: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  large: PropTypes.bool,
};

Testimonial.defaultProps = {
  large: false,
};

export default Testimonial;
